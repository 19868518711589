@tailwind base;
@tailwind components;
@tailwind utilities;

.ql-editor img {
  display: inline !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#error {
  opacity: 0;
  transition: all;
  animation-name: error;
  animation-duration: 4s;
}

@keyframes error {
  0%, 80% {
      opacity: 1;
  }
  100% {
      opacity: 0;
  }
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}